import React, { useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import FadeIn from 'react-fade-in';
import ReactDOM from 'react-dom';
import { db } from './utils/firebase';
import { getDatabase, ref, onValue } from "firebase/database";


//create your forceUpdate hook
function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update the state to force render
}

function handleLogoClick(){
  window.open("https://lnkvrs.xyz/");
}

const Item = (props) => {
  let randomAsset = {};
  if(props.assets){
    randomAsset = props.assets[Math.floor(Math.random() * props.assets.length)]
  }
  const forceUpdate = useForceUpdate();
  return(
    <FadeIn delay={props.delay*200}>
    <div className="max-w-xs rounded-lg overflow-hidden grid justify-items-stretch bg-white" 
    style={{
      "boxShadow": "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
      }}>
    
      <img className="w-48 rounded justify-self-center px-3 py-2" src={randomAsset.image_preview_url}
  onError={({ currentTarget }) => {
    currentTarget.onerror = null; // prevents looping
    currentTarget.src="image_path_here";
  }} />
      <div className="px-4 pb-2">
        <div className="font-bold text-m tracking-tighter text-[#353840] font-['Alfa Slab One']">{randomAsset.name}</div>
      </div>
    </div>
    </FadeIn>
  )
}

const Left = (props) => {
  return(
    <div id="left" className="flex gap-3 col-span-1 md:col-span-3">
    <div className="grow hidden md:block" 
      style={{
        "flexGrow": "1"
      }}>
      <div className="grid grid-rows-7 grid-flow-col gap-10 space-y-16">
        <div className="col-span-2 row-start-4 row-end-5 flex justify-self-end">
          <Item assets={props.data.assets} delay={1}/>
        </div>
        <div className="row-end-7 col-span-2 flex justify-self-end">
          <Item assets={props.data.assets} delay={2}/>
        </div>
      </div>
    </div>
    <div className="grow hidden lg:block" 
      style={{
        "flexGrow": "1"
      }}>
      <div className="grid grid-rows-7 grid-flow-col gap-12 py-19">
        <div className="col-span-2 row-start-7 flex justify-center">
        <Item assets={props.data.assets} delay={3}/>
        </div>
      </div>
    </div>
  </div>
  )
}

const Right = (props) => {
  return(<div id="right" className="flex gap-3 col-span-1 md:col-span-3">
  <div className="grow hidden lg:block" 
    style={{
      "flexGrow": "1"
    }}>
    <div className="grid grid-rows-7 grid-flow-col gap-12 py-19">
      <div className="col-span-2 row-start-7 flex justify-center">
        <Item assets={props.data.assets} delay={4}/>
      </div>
    </div>
  </div>
  <div className="grow hidden md:block" 
    style={{
      "flexGrow": "1"
    }}>
    <div className="grid grid-rows-7 grid-flow-col gap-10 space-y-16">
      <div className="col-span-2 row-start-4 row-end-5 flex justify-self-start">
        <Item assets={props.data.assets} delay={5}/>
      </div>
      <div className="row-end-7 col-span-2 flex justify-self-start">
        <Item assets={props.data.assets} delay={6}/>
      </div>
    </div>
  </div>
</div>)
}

const OpenSeaButton = (props) => {
  return (
      <div className="pt-6 text-center space-y-4">
        <a id="opensea" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-blue-300 fill-current" viewBox="0 0 300.000000 300.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill="#1F2937" stroke="none">
              <path
                d="M1265 2975 c-656 -106 -1159 -627 -1246 -1290 -16 -126 -6 -373 21
                  -500 66 -307 250 -618 483 -813 202 -170 423 -280 667 -333 90 -20 134 -23
                  310 -23 176 0 220 3 310 23 586 127 1037 585 1157 1177 28 138 25 453 -6 594
                  -124 575 -575 1027 -1146 1150 -132 28 -418 36 -550 15z m347 -602 c20 -21 23
                  -37 26 -111 l4 -87 40 -30 c66 -50 231 -227 267 -289 117 -197 91 -332 -109
                  -548 l-62 -68 -69 0 -69 0 0 -80 0 -80 89 0 c49 0 102 5 118 11 15 5 56 37 89
                  70 l61 61 234 67 c129 38 237 66 242 64 4 -2 7 -32 7 -67 l0 -62 -59 -29 c-78
                  -37 -116 -75 -186 -187 -104 -167 -148 -214 -230 -254 l-50 -24 -431 0 c-250
                  0 -454 4 -485 10 -75 14 -185 69 -243 121 -100 89 -170 239 -164 350 l3 54
                  230 0 230 0 7 -47 c15 -107 70 -138 243 -138 l115 0 0 80 0 80 -109 0 c-67 0
                  -112 4 -116 11 -4 5 8 34 27 62 97 146 148 275 148 372 0 122 -50 298 -138
                  487 -25 54 -42 101 -39 104 3 3 55 -8 116 -24 l111 -30 0 60 c0 71 11 95 52
                  112 39 17 71 10 100 -21z m-426 -349 c80 -182 101 -361 53 -464 -14 -31 -38
                  -75 -53 -98 l-26 -42 -203 0 c-111 0 -208 4 -216 9 -11 7 23 66 154 272 213
                  334 243 379 256 379 6 0 21 -25 35 -56z"
              />
            </g>
          </svg>

          <div className="pl-4">{props.link.title}</div>
          <div className="ml-auto mt-0.5 pl-4">
            <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
              <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </div>
        </a>
      </div>
  )
}

const LooksRareButton = (props) => {
  return(
    <div className="pt-6 text-center space-y-4">
      <a id="looksrare" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-blue-300 fill-current"
          viewBox="0 0 934.000000 934.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,934.000000) scale(0.100000,-0.100000)"
          fill="#1F2937" stroke="none">
          <path d="M1472 6477 l-1202 -1202 2023 -2023 2022 -2022 2023 2023 2022 2022
          -1203 1203 -1202 1202 -1640 0 -1640 0 -1203 -1203z m3048 428 c364 -20 790
          -153 1128 -354 250 -147 325 -213 880 -765 l512 -511 -467 -468 c-258 -258
          -513 -507 -568 -553 -216 -184 -538 -370 -801 -463 -141 -50 -348 -102 -505
          -128 -163 -26 -605 -26 -769 1 -151 24 -390 84 -515 130 -265 98 -578 280
          -790 460 -55 46 -310 295 -568 553 l-467 468 512 511 c423 421 535 527 639
          605 418 311 909 490 1419 518 131 8 160 7 360 -4z"/>
          <path d="M4200 6530 c-352 -32 -684 -219 -896 -506 -114 -153 -197 -345 -229
          -528 -19 -112 -20 -328 0 -438 79 -455 412 -836 856 -981 219 -72 512 -76 739
          -10 265 76 518 260 679 493 153 221 221 443 221 715 0 218 -42 393 -137 578
          -235 454 -723 722 -1233 677z m244 -730 c107 -27 169 -63 252 -145 81 -80 126
          -162 149 -270 18 -88 18 -132 0 -220 -40 -189 -176 -341 -365 -406 -50 -17
          -87 -22 -165 -22 -161 0 -290 56 -398 173 -88 95 -147 241 -147 365 0 136 62
          278 164 381 141 140 323 192 510 144z"/>
          </g>
          </svg>
          
          <div className="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const WebsiteButton = (props) => {
  return(
    <div className="pt-6 text-center space-y-4">
      <a id="website" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
          className="w-6 h-6 text-blue-300 fill-current"
          viewBox="0 0 512.000000 512.000000"
          preserveAspectRatio="xMidYMid meet">

          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#1F2937" stroke="none">
          <path d="M361 5109 c-172 -34 -318 -182 -351 -358 -6 -35 -10 -511 -10 -1352
          l0 -1299 2560 0 2560 0 0 1299 c0 841 -4 1317 -10 1352 -34 180 -179 325 -359
          359 -67 12 -4325 12 -4390 -1z m1369 -919 c26 -13 47 -34 60 -60 19 -37 20
          -58 20 -370 0 -312 -1 -333 -20 -370 -13 -26 -34 -47 -60 -60 -37 -19 -58 -20
          -370 -20 -312 0 -333 1 -370 20 -26 13 -47 34 -60 60 -19 37 -20 58 -20 370 0
          312 1 333 20 370 12 24 35 47 58 59 35 19 58 20 370 21 314 0 335 -1 372 -20z
          m1200 0 c26 -13 47 -34 60 -60 19 -37 20 -58 20 -370 0 -312 -1 -333 -20 -370
          -13 -26 -34 -47 -60 -60 -37 -19 -58 -20 -370 -20 -312 0 -333 1 -370 20 -26
          13 -47 34 -60 60 -19 37 -20 58 -20 370 0 312 1 333 20 370 12 24 35 47 58 59
          35 19 58 20 370 21 314 0 335 -1 372 -20z m1200 0 c26 -13 47 -34 60 -60 19
          -37 20 -58 20 -370 0 -312 -1 -333 -20 -370 -13 -26 -34 -47 -60 -60 -37 -19
          -58 -20 -370 -20 -312 0 -333 1 -370 20 -26 13 -47 34 -60 60 -19 37 -20 58
          -20 370 0 312 1 333 20 370 12 24 35 47 58 59 35 19 58 20 370 21 314 0 335
          -1 372 -20z m-2400 -1200 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -37
          -19 -58 -20 -370 -20 -312 0 -333 1 -370 20 -45 23 -80 80 -80 130 0 48 35
          107 78 129 35 19 58 20 370 21 314 0 335 -1 372 -20z m1200 0 c45 -23 80 -80
          80 -130 0 -50 -35 -107 -80 -130 -37 -19 -58 -20 -370 -20 -312 0 -333 1 -370
          20 -45 23 -80 80 -80 130 0 48 35 107 78 129 35 19 58 20 370 21 314 0 335 -1
          372 -20z m1200 0 c45 -23 80 -80 80 -130 0 -50 -35 -107 -80 -130 -37 -19 -58
          -20 -370 -20 -312 0 -333 1 -370 20 -45 23 -80 80 -80 130 0 48 35 107 78 129
          35 19 58 20 370 21 314 0 335 -1 372 -20z"/>
          <path d="M1210 3760 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z"/>
          <path d="M2410 3760 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z"/>
          <path d="M3610 3760 l0 -150 150 0 150 0 0 150 0 150 -150 0 -150 0 0 -150z"/>
          <path d="M0 1711 c0 -113 13 -180 50 -259 56 -120 183 -216 319 -242 74 -14
          4308 -14 4382 0 180 34 325 179 359 359 5 30 10 94 10 142 l0 89 -2560 0
          -2560 0 0 -89z"/>
          <path d="M1870 895 c0 -3 32 -68 70 -145 39 -77 70 -142 70 -145 0 -3 -85 -5
          -189 -5 -103 0 -212 -5 -242 -10 -191 -36 -334 -189 -362 -386 -10 -68 -9 -78
          11 -119 15 -31 33 -50 61 -65 40 -20 53 -20 1271 -20 1218 0 1231 0 1271 20
          28 15 46 34 61 65 20 41 21 51 11 119 -28 197 -171 350 -362 386 -30 5 -139
          10 -242 10 -104 0 -189 2 -189 5 0 3 32 68 70 145 38 77 70 142 70 145 0 3
          -310 5 -690 5 -379 0 -690 -2 -690 -5z"/>
          </g>
          </svg>
          
          <div className="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const DiscordButton = (props) => {
  return(
    <div className="pt-6 text-center space-y-4">
      <a id="discord" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg 
          className="w-6 h-6 text-blue-300 fill-current"
          
          viewBox="0 0 24 24" 
          xmlns="http://www.w3.org/2000/svg">
          <path fill="#1F2937" d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"/>
          </svg>
          <div className="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const TwitterButton = (props) => {
  return(
    <div className="pt-6 text-center space-y-4">
      <a id="twitter" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg
          className="w-6 h-6 fill-current"
          xmlns="http://www.w3.org/2000/svg"
          
          viewBox="0 0 24 24">
          <path
          fill="#1F2937"
              d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"
          />
          </svg>
          <div className="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const MediumButton = (props) => {
  return(
    <div id="mediumDiv" className="pt-6 text-center space-y-4">
      <a id="medium" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg 
          className="w-6 h-6 text-blue-300 fill-current"
          
          viewBox="0 0 338.000000 338.000000" 
          xmlns="http://www.w3.org/2000/svg">
          
          <g transform="translate(0.000000,338.000000) scale(0.100000,-0.100000)"
          fill="#1F2937" stroke="none">
          <path d="M960 2333 c-292 -37 -520 -222 -615 -498 -39 -115 -46 -286 -16 -404
          106 -420 534 -657 942 -521 99 33 196 96 279 179 139 142 200 283 208 487 3
          100 1 135 -16 202 -67 262 -267 468 -516 532 -77 19 -207 31 -266 23z"/>
          <path d="M2112 2264 c-98 -49 -181 -180 -229 -364 -35 -131 -43 -366 -18 -507
          41 -232 143 -410 261 -459 48 -20 130 -18 179 6 158 76 265 345 265 665 0 461
          -220 778 -458 659z"/>
          <path d="M2756 2205 c-28 -28 -58 -130 -78 -265 -17 -116 -19 -169 -15 -385 6
          -278 25 -423 69 -511 19 -38 30 -50 51 -52 21 -3 29 3 45 35 60 116 87 337 79
          643 -6 227 -24 375 -56 461 -32 82 -62 106 -95 74z"/>
          </g>
          </svg>
          <div className="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const InstagramButton = (props) => {
  return(
    <div id="mediumDiv" className="pt-6 text-center space-y-4">
      <a id="medium" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
          <svg 
          class="w-6 h-6 text-blue-300 fill-current"
          
          viewBox="0 0 512.000000 512.000000" 
          xmlns="http://www.w3.org/2000/svg">
          
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#1F2937" stroke="none">
          <path d="M2315 5109 c-800 -83 -1501 -518 -1927 -1196 -604 -960 -491 -2229
          272 -3065 423 -463 951 -740 1585 -830 118 -17 511 -16 635 0 579 80 1097 337
          1491 739 130 132 157 163 243 277 256 341 423 757 488 1211 17 118 17 512 0
          630 -43 303 -122 561 -247 814 -134 268 -270 459 -483 674 -395 400 -889 649
          -1457 733 -123 18 -478 26 -600 13z m848 -1089 c288 -19 456 -82 610 -228 156
          -149 228 -332 247 -629 13 -195 13 -1006 0 -1204 -19 -300 -93 -485 -253 -636
          -149 -141 -321 -205 -604 -223 -192 -12 -1014 -12 -1206 0 -288 19 -456 82
          -610 228 -156 149 -228 332 -247 629 -13 194 -12 1028 0 1212 20 293 92 475
          247 623 148 140 325 210 582 227 169 12 1061 12 1234 1z"/>
          <path d="M2195 3759 c-300 -6 -349 -9 -416 -27 -213 -58 -334 -178 -391 -391
          -18 -67 -21 -117 -27 -429 -3 -193 -3 -511 0 -705 6 -311 9 -361 27 -428 57
          -213 178 -334 391 -391 67 -18 117 -21 429 -27 193 -3 511 -3 705 0 313 6 361
          9 430 28 207 55 333 180 389 388 18 70 21 116 27 431 3 193 3 511 0 705 -6
          312 -9 361 -27 428 -57 213 -179 335 -391 391 -78 20 -98 21 -661 31 -80 1
          -298 -1 -485 -4z m1212 -250 c38 -14 83 -57 99 -96 49 -113 -38 -243 -161
          -243 -94 0 -175 80 -175 174 0 121 124 208 237 165z m-604 -236 c121 -42 188
          -85 283 -178 92 -91 149 -181 191 -300 26 -74 28 -91 28 -235 0 -144 -2 -161
          -28 -235 -40 -115 -100 -209 -186 -296 -87 -86 -181 -146 -296 -186 -74 -26
          -91 -28 -235 -28 -144 0 -161 2 -235 28 -119 42 -209 99 -300 191 -93 95 -135
          162 -179 283 -30 85 -31 94 -31 243 1 141 3 162 28 235 52 155 163 301 297
          390 146 97 251 126 440 122 120 -3 144 -7 223 -34z"/>
          <path d="M2442 3034 c-263 -70 -423 -334 -357 -588 48 -183 178 -313 362 -361
          344 -90 678 244 588 588 -70 267 -337 430 -593 361z"/>
          </g>
          </svg>
          <div class="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const ForbesButton = (props) => {
  return(
    <div id="mediumDiv" className="pt-6 text-center space-y-4">
      <a id="medium" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            class="w-6 h-6 text-blue-300 fill-current"
            viewBox="0 0 225.000000 225.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
            fill="#1F2937" stroke="none">
            <path d="M210 2176 l0 -44 64 -7 c123 -13 190 -70 220 -188 26 -100 39 -369
            38 -807 0 -446 -13 -719 -38 -817 -29 -116 -87 -166 -214 -186 l-70 -11 0 -43
            0 -43 585 0 585 0 0 43 0 43 -91 11 c-140 18 -191 62 -223 189 -19 75 -36 338
            -36 568 l0 178 148 -4 c103 -3 158 -9 182 -20 73 -31 130 -113 165 -231 l16
            -57 45 0 44 0 0 370 0 370 -44 0 -44 0 -27 -79 c-31 -93 -69 -150 -125 -187
            -37 -25 -53 -28 -190 -37 -82 -5 -157 -6 -166 -3 -15 6 -16 32 -10 269 3 144
            9 342 12 440 l6 177 227 0 c239 0 285 -6 375 -52 112 -57 240 -244 281 -410 8
            -32 9 -32 70 -16 l45 12 0 196 c0 107 -3 246 -7 308 l-6 112 -909 0 -908 0 0
            -44z"/>
            </g>
            </svg>
          <div class="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const JobsButton = (props) => {
  return(
    <div id="mediumDiv" className="pt-6 text-center space-y-4">
      <a id="medium" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
      <svg 
          class="w-6 h-6 text-blue-300 fill-current"
          
          viewBox="0 0 512.000000 512.000000" 
          xmlns="http://www.w3.org/2000/svg">
          
          <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#1F2937" stroke="none">
          <path d="M1960 4679 c-85 -13 -180 -57 -210 -99 -47 -66 -62 -141 -67 -337
          l-6 -183 165 0 166 0 7 98 c9 128 20 169 59 208 56 56 83 59 486 59 403 0 430
          -3 486 -59 39 -39 50 -79 59 -208 l7 -98 165 0 166 0 -6 183 c-7 245 -31 324
          -117 381 -89 59 -102 60 -725 62 -318 1 -599 -2 -635 -7z"/>
          <path d="M559 3910 c-135 -13 -219 -66 -268 -170 l-26 -55 0 -480 c0 -471 0
          -481 23 -560 83 -286 261 -417 637 -467 146 -19 3124 -19 3270 0 376 50 554
          180 637 467 23 79 23 89 23 560 0 440 -1 483 -18 520 -54 121 -131 170 -288
          185 -111 11 -3878 11 -3990 0z"/>
          <path d="M462 1394 c3 -639 5 -714 21 -760 27 -82 67 -128 144 -166 l68 -33
          1820 -3 c1331 -2 1838 0 1887 8 136 23 214 99 243 235 20 96 23 1431 3 1422
          -266 -120 -401 -137 -1110 -137 l-498 0 0 -292 c0 -162 -5 -309 -10 -329 -15
          -55 -57 -88 -126 -99 -32 -6 -187 -10 -344 -10 -157 0 -312 4 -344 10 -69 11
          -111 44 -126 99 -5 20 -10 167 -10 329 l0 292 -497 0 c-710 0 -841 16 -1111
          137 -10 4 -12 -128 -10 -703z"/>
          <path d="M2296 1944 c-24 -23 -24 -505 0 -528 23 -24 505 -24 528 0 24 23 24
          505 0 528 -23 24 -505 24 -528 0z"/>
          </g>
          </svg>
          <div class="pl-4">
          {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

const GenericButton = (props) => {
  return(
    <div id="mediumDiv" className="pt-6 text-center space-y-4">
      <a id="medium" href={props.link.url} target="_blank" className="flex items-start rounded-lg border border-gray-400 bg-white px-5 py-4 text-lg leading-6 font-medium shadow-md hover:shadow-xl transition ease-in-out duration-150">
        <svg class="w-6 h-6 text-blue-300 fill-current"
                viewBox="0 0 512.000000 512.000000" xmlns="http://www.w3.org/2000/svg">

                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="#1F2937" stroke="none">
                    <path d="M3550 4965 c-8 -2 -49 -9 -90 -15 -164 -27 -331 -95 -492 -202 -76
            -50 -164 -132 -483 -450 l-390 -389 55 8 c30 5 125 8 210 7 173 -1 314 -23
            467 -73 l88 -29 195 192 c118 116 217 204 252 224 256 150 570 111 779 -97
            208 -208 247 -522 97 -779 -23 -41 -177 -202 -553 -578 -475 -475 -526 -524
            -592 -558 -147 -75 -314 -95 -467 -56 -105 27 -187 72 -270 150 -76 71 -120
            134 -156 223 -14 33 -30 57 -38 57 -16 0 -472 -448 -472 -463 0 -5 27 -48 60
            -96 67 -97 202 -234 301 -304 146 -103 336 -181 522 -213 138 -24 387 -15 523
            20 183 46 354 132 508 257 39 31 299 286 577 566 425 427 516 523 567 601 147
            221 210 416 219 677 13 371 -112 691 -372 950 -249 250 -562 377 -915 374 -63
            -1 -122 -3 -130 -4z" />
                    <path d="M2115 3595 c-230 -42 -416 -128 -599 -276 -39 -31 -299 -286 -577
            -566 -424 -426 -516 -523 -567 -601 -147 -221 -210 -416 -219 -677 -13 -371
            112 -691 372 -950 259 -260 579 -385 950 -372 261 9 456 72 677 219 76 50 163
            131 483 450 l390 389 -55 -8 c-30 -4 -125 -8 -210 -7 -173 1 -314 23 -467 73
            l-88 29 -195 -192 c-118 -116 -217 -204 -252 -224 -256 -150 -570 -111 -779
            97 -208 209 -247 522 -97 779 23 41 177 202 553 578 470 470 527 524 590 557
            151 77 313 97 469 57 105 -27 187 -72 270 -150 74 -70 119 -133 155 -220 13
            -30 25 -56 26 -58 11 -12 75 45 268 236 119 119 217 220 217 225 0 22 -105
            163 -182 243 -189 196 -426 322 -694 369 -105 18 -341 18 -439 0z" />
                </g>
            </svg>
          
          
          <div class="pl-4">
            {props.link.title}
          </div>
          <div className="ml-auto mt-0.5 pl-4">
              <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
              </svg>
          </div>
      </a>
  </div>
  )
}

function  renderIconSwitch(param) {
  switch(param.icon) {
    case 'opensea':
      return <OpenSeaButton link={param} />;
    case 'looksrare':
      return <LooksRareButton link={param} />;
    case 'website':
      return <WebsiteButton link={param} />;
    case 'discord':
      return <DiscordButton link={param} />;
    case 'twitter':
      return <TwitterButton link={param} />;
    case 'medium':
      return <MediumButton link={param} />;
    case 'instagram':
      return <InstagramButton link={param} />;
    case 'forbes':
      return <ForbesButton link={param} />;
    case 'jobs':
      return <JobsButton link={param} />;
    case 'generic':
      return <GenericButton link={param} />;
  }
}

const Middle = (props) => {
  return (
    <div className="col-span-6 md:col-span-2">
    <div className="max-w-xs mx-auto">
      <div>
        <figure className="p-6">
          <img className="w-32 h-32 rounded-full mx-auto" src={props.data.brandImage} alt="profile picture" width="400" height="400" />
          <div className="pt-6 text-center space-y-4">
            <figcaption className="font-medium">
              <div id="name" className="text-cyan-900 text-xl">{props.data.name}</div>
              <div id="handle" className="text-gray-500 font-light">{props.data.handle}</div>
            </figcaption>
            <blockquote>
              <p id="description" style={{
                "width": "150%",
                "marginLeft": "-25%",
              }} className="text-lg font-medium">{props.data.description}</p>
            </blockquote>
          </div>
        </figure>
      </div>
    </div>
    <div className="max-w-xs mx-auto">
    {
        Object.entries(props.data?.links).map(([key, value]) => (
          <>{renderIconSwitch(value)}</>
        ))
    }      
    </div>

    <div className="max-w-7xl mx-auto">
        <div>
            <figure className="p-4">
                <img onClick={handleLogoClick} className="w-20 h-20 rounded-full mx-auto" src="./assets/logo.png" style={{"cursor": "pointer"}} alt="profile picture" width="400" height="400"/>
            </figure>
        </div>
    </div>
  </div>
  )
}


// const FullPage = (props) => {

  
// }

export function FullPage() {
  let { slug } = useParams();
  const [collection, setCollectionData] = useState();

  

  const getCollection = async () => {
    console.log(slug)
    try {
      let pageRef =  ref(db, slug)
      onValue(pageRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data)
        setCollectionData(data);


        document.title = data.title;
        const favicon = document.getElementById("favicon"); // Accessing favicon element
        if(data.favicon) favicon.href = data.favicon;
        console.log(favicon);
      });
      // await db.ref('ninjasquad').once('value')
      // .then(snap=>{
      //   console.log(snap.val())
      // })
      // await fetch('collections/'+slug+'.json',{
      //   headers : { 
      //     'Content-Type': 'application/json',
      //     'Accept': 'application/json'
      //    }
      // }
      // ).then(result=>{
      //   //console.log(result)
      //   return result.json()
      // }).then(json=>{
      //   console.log(json);
      //   setCollectionData(json);
      //   document.title = json.title;

      // })
    } catch (error) {
      console.log(error);
    }
    
    
  };

  useEffect(() => {
    
    getCollection();
  }, []);


  return(
    <div >
    {collection ? (
      <div className="min-h-screen bg-gray-100 bg-cover bg-center lg:bg-blend-normal grid grid-cols-8 gap-3">
        <Left data={collection}/>
        <Middle data={collection} />
        <Right data={collection}/>
      </div>
    ) : (
      <p>Loading</p>
    )}
    </div>)
}
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { FullPage } from './components/FullPage.js'

function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the scurrent URL. */}
        <Switch>
          <Route path="/:slug" component={FullPage} />
          <Route path="/" component={() => {
              window.location.replace('https://welcome.lnkvrs.xyz');
              return null;
            }} />
        </Switch>
        
      </div>
    </Router>
  );
}


// function Home() {
//   return <h2>Home</h2>;
// }


export default App;
